<template>
    <v-container fluid style="margin: 5%; padding: 0px; width: 100%">
      <v-layout wrap>
        <loader :active="loaderActive" message="Please wait..." />
        <v-alert elevation="3" border="top" :type="alertType" dismissible prominent dark v-model="alert" :title="alertTitle" :text="alertMessage">
        </v-alert>       
        </v-layout>
        </v-container>

    <div id='links' style="display: none;">
        <div class="market_list">
            <template v-for="item in items" :key="item.platform">
                <a :href="item.link"><img class="image" :src="item.image_path" /></a>
            </template>
        </div>
    </div>
  </template>

  <script>
    import Loader from './Loader.vue'
    import loaderMixin from '../mixin/loader.js';
    import titleMixin from '../mixin/titleMixin'
    import $ from 'jquery'

    export default {
      title: "Marketplace Link",
    components: {
      Loader
    },
    mixins: [loaderMixin, titleMixin],
    data() {
      return {
        alertMessage: "Error occured!",
        alertTitle: "Error",
        alertType: "error",
        alert: false,
        items: [
        {
            platform: 'iOS',
            link: '',
            image_path: 'https://mcusercontent.com/59b7ef2c34bd28e52f757e805/images/9c227fd7-6d8a-4321-bba4-f4a416dfccbe.png',
        },
        {
            platform: 'Android',
            link: '',
            image_path: 'https://mcusercontent.com/59b7ef2c34bd28e52f757e805/images/61fd682c-ebb6-4dca-8b93-075599a91c57.png',
        },
        ],
      }
    },
    methods: {
      showAlert(title, text) {
        this.alertMessage = text
        this.alertTitle = title
        this.alert = true
    
      },
      async navigateToMarketPlace() {
        $('#root').hide()
        this.showLoader()
        this.alertVisible = false
        var data = this.$route.query.data
        if (data == undefined) {
          data = this.$route.params.data
          if (data == undefined) {
            this.showAlert('Error', 'No data provided.')
            return
          }
        }
        const base64url = require('base64url');
        try {
            const json = JSON.parse(base64url.decode(data))
            const Android = json['Android'];
            const iOS = json['iOS'];
            if (iOS == undefined || Android == undefined) {
                throw Error
            }
            const AndroidURL = "https://play.google.com/store/apps/details?id=" + Android
            const iOSURL = "https://apps.apple.com/app/" + iOS
            this.hideLoader();
            this.items[0].link = iOSURL;
            this.items[1].link = AndroidURL;
            $('#links').show();

            if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
                this.items.pop()
                window.location.href = 'market://details?id=' + Android;
            } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
                this.items.shift()
                window.location.href = 'itms-apps://itunes.apple.com/app/' + iOS;
            } else if (navigator.userAgent.toLowerCase().indexOf("ipad") > -1) {
                this.items.shift()
                window.location.href = 'itms-apps://itunes.apple.com/app/' + iOS;
            } else if (navigator.userAgent.toLowerCase().indexOf("ipod") > -1) {
                this.items.shift()
                window.location.href = 'itms-apps://itunes.apple.com/app/' + iOS;
            } 

            
        } catch {
            this.hideLoader();
            this.showAlert('Error', 'Data is wrong.')
            return
        }
        

      }
    },
    mounted () {
     this.navigateToMarketPlace()
    },
    created() {
      
    }
  };
    </script>
  
  <style>
  body,
  html {
    height: 100vh;
    display: grid;
    place-items: center;
  }
  
  .v-alert {
    position: fixed;
    top: 10%;
    left: 50%;
    min-height: 150px;
    transform: translate(-50%, -50%);
    margin: 0 auto; 
  }
  
  .json-container {
    min-width: 1200px;
    margin-top: 20px;
    max-width: 1500px;
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
  
  .market_list{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    }

  #app {
    width: 100%;
  }
  
  #root {
    margin: auto;
  }
  #change {
    text-align: left;
    background-color:white;
  }
  #change pre {
    background-color: aliceblue;
  }
  p {
    margin-top: 0;
    font-size: 20px;
  }
  button {
    font-size: 48px;
  }
  
  </style>